import React from "react";
import "./MedicalMain.css";
import { ProductsData } from "../App";

function MedicalMain() {
  return (

    <div className="medical_main">
      <div
        className="main"
        style={{
          backgroundImage: `url(${
            ProductsData.ProductsBackgroundImage
              ? ProductsData.ProductsBackgroundImage[0].image
              : null
          })`,
        }}
      >
        <div className="overlay">
          <h1 className="heading">Medical Products</h1>
        </div>
      </div>

      <div className="medical_main_body">
        <div></div>

        <div className="title medical_head">
          <h1>
            {ProductsData.MedicalMain
              ? ProductsData.MedicalMain[0].title
              : null}
          </h1>
        </div>

        <div className="description">
          {ProductsData.MedicalMain
            ? ProductsData.MedicalMain[0].description.map((data) => {
                return <p>{data.content}</p>;
              })
            : null}
        </div>

        {ProductsData.MedicalMain
          ? ProductsData.MedicalMain[0].sub.map((data) => {
              return (
                <div className="sub">
                  <div className="sub_title">
                    <h3>{data.title}</h3>
                  </div>

                  <div className="sub_description">
                    <ul>
                      {data.description.map((data1) => {
                        return <li>{data1.content}</li>;
                      })}
                    </ul>
                  </div>
                </div>
              );
            })
          : null}
      </div>
    </div>
    
  );
}

export default MedicalMain;
