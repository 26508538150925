import React, { useState } from "react";
import "./Navbar.css";
import { NavLink, useLocation } from "react-router-dom";
import { ServicesData, ProductsData } from "../App";

function Navbar() {

  return (
    <div>
      <header className="main-header">
        <div className="">
          <nav className="navbar navbar-expand-lg main-nav px-0">
            <a className="navbar-brand headname" href="/">
              <h2>HERRMANN RESEARCH PRIVATE LIMITED</h2>
              <span>ONE STOP SOLUTION FOR ENVIRONMENTAL TESTING</span>
            </a>

            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#mainMenu"
              aria-controls="mainMenu"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="icon-bar icon-bar-1"></span>
              <span className="icon-bar icon-bar-2"></span>
              <span className="icon-bar icon-bar-3"></span>
            </button>
            <div className="collapse navbar-collapse" id="mainMenu">
              <ul className="navbar-nav ml-auto text-uppercase f1">
                <li>
                  <NavLink
                    activeClassName="active"
                    to="/"
                    data-toggle="collapse"
                    data-target="#mainMenu"
                  >
                    home
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    activeClassName="active"
                    to="/about"
                    data-toggle="collapse"
                    data-target="#mainMenu"
                  >
                    about us
                  </NavLink>
                </li>
                <li className="menu_children">
                  <NavLink
                    activeClassName="active"
                    to="/services"
                    data-toggle="collapse"
                    data-target="#mainMenu"
                  >
                    Services
                  </NavLink>

                  <div className="sub_menu mega_menu single_column sub_menu1">
                    <div className="list_item">
                      {ServicesData.TestingData
                        ? ServicesData.TestingData.slice(0, 10).map(
                            (data, index) => {
                              return (
                                <li>
                                  <NavLink to={data.path}>{data.title}</NavLink>
                                </li>
                              );
                            }
                          )
                        : null}
                    </div>

                    <div className="list_item">
                      {ServicesData.TestingData
                        ? ServicesData.TestingData.slice(10, 20).map(
                            (data, index) => {
                              return (
                                <li>
                                  <NavLink
                                    activeClassName="active"
                                    to={data.path}
                                    data-toggle="collapse"
                                  >
                                    {data.title}
                                  </NavLink>
                                </li>
                              );
                            }
                          )
                        : null}
                    </div>
                  </div>
                </li>

                <li class="menu_children">
                  <NavLink
                    activeClassName="active"
                    to="/products"
                    data-toggle="collapse"
                    data-target="#mainMenu"
                  >
                    products
                  </NavLink>
                  <div class="sub_menu mega_menu1 single_column">
                    {ProductsData.MedicalData
                      ? ProductsData.MedicalData.map((data, index) => {
                          return (
                            <li>
                              <NavLink
                                activeClassName="active"
                                to={data.path}
                                data-toggle="collapse"
                              >
                                {data.title}
                              </NavLink>
                            </li>
                          );
                        })
                      : null}
                  </div>
                </li>

                <li>
                  <NavLink
                    activeClassName="active"
                    to="/certification"
                    data-toggle="collapse"
                    data-target="#mainMenu"
                  >
                    certification
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    activeClassName="active"
                    to="/contact"
                    data-toggle="collapse"
                    data-target="#mainMenu"
                  >
                    contact
                  </NavLink>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
    </div>
    
  );
}

export default Navbar;
