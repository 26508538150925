import React, { useState } from "react";
import "./Side.css";
import { ProductsData } from "../App";

function MedicalSide() {
  const [query, setQuery] = useState("");
  const [display, setDisplay] = useState(false);

  const show = () => {
    setDisplay(true);
  };

  const hide = () => {
    setDisplay(false);
  };

  return (

    <div className="side_menu">
      <nav className="menu">
        <input id="menu__toggle" type="checkbox" className="menu__toggle" />
        <label for="menu__toggle" className="menu__toggle-label">
          <svg preserveAspectRatio="xMinYMin" viewBox="0 0 24 24">
            <path d="M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z" />
          </svg>
          <svg preserveAspectRatio="xMinYMin" viewBox="0 0 24 24">
            <path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
          </svg>
        </label>
        <ol className="menu__content">
          {ProductsData.MedicalData
            ? ProductsData.MedicalData.map((data, index) => {
                return (
                  <li className="menu-item" key={index}>
                    <a href={data.path}>{data.title}</a>
                  </li>
                );
              })
            : null}
        </ol>
      </nav>

      <div className="form-outline search">
        <input
          type="text"
          className="form-control search_box"
          placeholder="Search"
          onChange={(event) => setQuery(event.target.value)}
          onFocus={show}
          onBlur={hide}
        />

        <ul className="search_panel">
          {
            //create a new array by filtering the original array

            display === true
              ? ProductsData.MedicalData.filter((el) => {
                  if (query === "") {
                    return null;
                  } else if (
                    el.title.toLowerCase().includes(query.toLowerCase())
                  ) {
                    return el;
                  }
                }).map((el) => {
                  return (
                    <p key={el.id}>
                      <a href={el.path} style={{ color: "black" }}>
                        {el.title}
                      </a>
                    </p>
                  );
                })
              : null
          }
        </ul>
      </div>
    </div>
    
  );
}

export default MedicalSide;
