import React from "react";
import "./TestingContent.css";
import { ServicesData } from "../App";

function TestingContent(props) {
  return (

    <div className="testing">
      <div
        className="main"
        style={{
          backgroundImage: `url(${
            ServicesData.ServicesBackgroundImage
              ? ServicesData.ServicesBackgroundImage[0].image
              : null
          })`,
        }}
      >
        <div className="overlay">
          <h1 className="heading ">Environmental Testing</h1>
        </div>
      </div>

      <div className="testing_body">
        {props.image ? (
          <div className="image">
            <img src={props.image} />
          </div>
        ) : null}

        {props.sliding ? (
          <div className="image">
            <div
              id="carouselDarkVariant"
              className="carousel slide carousel-fade z-depth-1-half carousel-dark"
              data-ride="carousel"
            >
              {/*Indicators*/}
              <ol className="carousel-indicators">
                <li
                  data-target="#carouselDarkVariant"
                  data-slide-to={0}
                  className="active"
                />
                {props.sliding.slice(1, 10).map((data) => {
                  return (
                    <li
                      data-target="#carouselDarkVariant"
                      data-slide-to={data.id}
                    />
                  );
                })}
              </ol>
              {/*/.Indicators*/}
              {/*Slides*/}
              <div className="carousel-inner" role="listbox">
                <div className="carousel-item active">
                  <div className="view">
                    <img
                      className="d-block w-100"
                      src={props.sliding[0].image}
                      alt="First slide"
                    />
                  </div>
                </div>

                {props.sliding.slice(1, 10).map((data) => {
                  return (
                    <div className="carousel-item">
                      <div className="view">
                        <img
                          className="d-block w-100"
                          src={data.image}
                          alt="Second slide"
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
              {/*/.Slides*/}
              {/*Controls*/}
              <a
                className="carousel-control-prev"
                href="#carouselDarkVariant"
                role="button"
                data-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                />
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="carousel-control-next"
                href="#carouselDarkVariant"
                role="button"
                data-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                />
                <span className="sr-only">Next</span>
              </a>
              {/*/.Controls*/}
            </div>
          </div>
        ) : null}

        <div className="title">
          <h1>{props.title}</h1>
        </div>

        <div className="description">
        {props.description
          ? props.description.map((data) => {
              return (
                  <p>{data.content}</p>
              );
            })
          : null}
          </div>

        {props.parameters
          ? props.parameters.map((data) => {
              return (
                <div className="parameters">
                  <h2 className="parameters_title">{data.title}</h2>
                  <p className="parameters_description">{data.value}</p>
                </div>
              );
            })
          : null}

        {props.sub_title ? (
          <div className="parameters">
            <ol>
              {props.sub_title.map((data) => {
                return (
                  <div>
                    <h2 className="sub_title">
                      <li>{data.title}</li>
                    </h2>

                    <div className="sub_description">
                    {data.description
                      ? data.description.map((data1) => {
                          return (
                              <p>{data1.content}</p>
                          );
                        })
                      : null}
                      </div>

                    {data.parameters
                      ? data.parameters.map((data1) => {
                          return (
                            <div className="sub_parameters">
                              <h2 className="parameters_title">
                                {data1.title}
                              </h2>
                              <p className="parameters_description">
                                {data1.value}
                              </p>
                            </div>
                          );
                        })
                      : null}
                  </div>
                );
              })}
            </ol>
          </div>
        ) : null}

        {props.standards ? (
          <div className="parameters parameters_1">
            <h2 className="parameters_title">Standards</h2>
            <div className="parameters_description_1">
              {props.standards.map((data) => {
                return <p className="parameters_description_2">{data.value}</p>;
              })}
            </div>
          </div>
        ) : null}

      </div>
    </div>
    
  );
}

export default TestingContent;
