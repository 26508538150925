import React from "react";
import "./About.css";
import { AboutData } from "../App";
import Mission from "./Mission";

function About() {
  return (

    <div className="about">
      <div className="about_body">
        <div className="about_imga">
          <img src={AboutData.About ? AboutData.About[0].image : null} />
        </div>
        <div>
          <h1 className="about_head">About Us</h1>
          <div className="about_description">
            {AboutData.About
              ? AboutData.About[0].description.map((data, index) => {
                  return <p>{data.content}</p>;
                })
              : null}
          </div>
        </div>
      </div>

      <div className="about_body">
        <div>
          <h1 className="about_head1">Why Us?</h1>
          <div className="about_description1">
            {AboutData.About
              ? AboutData.About[1].description.map((data, index) => {
                  return <p>{data.content}</p>;
                })
              : null}
          </div>
        </div>
        <div className="about_imgw">
          <img src={AboutData.About ? AboutData.About[1].image : null} />
        </div>
      </div>

      <div>
        <Mission />
      </div>

      <div className="values_body">
        <h1 className="values_head">Our Values</h1>
        <div className="values">
          {AboutData.About
            ? AboutData.About[4].sub.map((data, index) => {
                return (
                  <div className="value">
                    <img src={data.image} />
                    <h2>{data.heading}</h2>
                  </div>
                );
              })
            : null}
        </div>
      </div>

      <div className="team_body">
        <div>
          <h1 className="team_head">Professional Team</h1>
          <div className="team_description">
            <p>{AboutData.About ? AboutData.About[5].description : null}</p>
          </div>
        </div>
      </div>
    </div>
    
  );
}

export default About;
