import React from "react";
import "./TestingMain.css";
import { ServicesData } from "../App";

function TestingMain() {
  return (

    <div className="testing_main">
      <div
        className="main"
        style={{
          backgroundImage: `url(${
            ServicesData.ServicesBackgroundImage
              ? ServicesData.ServicesBackgroundImage[0].image
              : null
          })`,
        }}
      >
        <div className="overlay">
          <h1 className="heading">Environmental Testing</h1>
        </div>
      </div>

      <div className="testing_main_body">
        <div>
          <div className="title testing_head">
            <h1>
              {ServicesData.TestingMain
                ? ServicesData.TestingMain[0].title
                : null}
            </h1>
          </div>

          <div className="description">
            {ServicesData.TestingMain
              ? ServicesData.TestingMain[0].description.map((data) => {
                  return <p>{data.content}</p>;
                })
              : null}
          </div>
        </div>
      </div>
    </div>
    
  );
}

export default TestingMain;
