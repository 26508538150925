import React from "react";
import "./SlidingImages.css";
import { HomeData } from "../App";

function SlidingImages() {
  return (

    <div className="sliding_images">
      <div
        id="carousel-example-2"
        className="carousel slide carousel-fade z-depth-1-half"
        data-ride="carousel"
      >
        {/*Indicators*/}
        <ol className="carousel-indicators">
          <li
            data-target="#carousel-example-2"
            data-slide-to={0}
            className="active"
          />
          <li data-target="#carousel-example-2" data-slide-to={1} />
          <li data-target="#carousel-example-2" data-slide-to={2} />
        </ol>
        {/*/.Indicators*/}
        {/*Slides*/}
        <div className="carousel-inner" role="listbox">
          <div className="carousel-item active">
            <div className="view">
              <img
                className="d-block w-100"
                src={HomeData.Home ? HomeData.Home[0].sub[0].image : null}
                alt="First slide"
              />
              <div className="mask rgba-black-slight" />
            </div>
            <div className="overlays"></div>
            <div className="carousel-caption">
              <h3 className="h3-responsive">
                {HomeData.Home ? HomeData.Home[0].sub[0].heading : null}
              </h3>
              <p>{HomeData.Home ? HomeData.Home[0].sub[0].type : null}</p>
            </div>
          </div>
          <div className="carousel-item">
            {/*Mask color*/}
            <div className="view">
              <img
                className="d-block w-100"
                src={HomeData.Home ? HomeData.Home[0].sub[1].image : null}
                alt="Second slide"
              />
              <div className="mask rgba-black-slight" />
            </div>
            <div className="overlays"></div>
            <div className="carousel-caption">
              <h3 className="h3-responsive">
                {HomeData.Home ? HomeData.Home[0].sub[1].heading : null}
              </h3>
              <p>{HomeData.Home ? HomeData.Home[0].sub[1].type : null}</p>
            </div>
          </div>
          <div className="carousel-item">
            {/*Mask color*/}
            <div className="view">
              <img
                className="d-block"
                src={HomeData.Home ? HomeData.Home[0].sub[2].image : null}
                alt="Third slide"
              />
              <div className="mask rgba-black-slight" />
            </div>
            <div className="overlays"></div>
            <div className="carousel-caption">
              <h3 className="h3-responsive">
                {HomeData.Home ? HomeData.Home[0].sub[2].heading : null}
              </h3>
              <p>{HomeData.Home ? HomeData.Home[0].sub[2].type : null}</p>
            </div>
          </div>
        </div>
        {/*/.Slides*/}
        {/*Controls*/}
        <a
          className="carousel-control-prev"
          href="#carousel-example-2"
          role="button"
          data-slide="prev"
        >
          <span className="carousel-control-prev-icon" aria-hidden="true" />
          <span className="sr-only">Previous</span>
        </a>
        <a
          className="carousel-control-next"
          href="#carousel-example-2"
          role="button"
          data-slide="next"
        >
          <span className="carousel-control-next-icon" aria-hidden="true" />
          <span className="sr-only">Next</span>
        </a>
        {/*/.Controls*/}
      </div>
    </div>
    
  );
}

export default SlidingImages;
