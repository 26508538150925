import React, { useState } from "react";
import "./HomeCard.css";
import "../About/About.css";
import { useNavigate } from "react-router-dom";
import { HomeData } from "../App";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

function HomeCard() {

  const navigate = useNavigate();
  const [focus, setFocus] = useState(false);

  const visible = (isVisible) => {
    if (isVisible) {
      setFocus(true);
    }
  };

  return (
    <div className="home_card_body">
      <div className="home_cards">
        <h1 className="home_cards_head">Our Services</h1>

        <div className="home_card">
          {HomeData.Home
            ? HomeData.Home[1].sub.map((data, index) => {
                return (
                  <div className="card card-cascade">
                    <div className="view view-cascade overlay">
                      <img
                        className="card-img-top imgs"
                        src={data.image}
                        alt="Card image cap"
                      />
                    </div>
                    <div className="card-body card-body-cascade text-center">
                      <h4 className="card-title">{data.heading}</h4>
                    </div>
                    <a className="card_btn" href={data.path}>
                      <div className="card-footer text-muted text-center">
                        Read More
                      </div>
                    </a>
                  </div>
                );
              })
            : null}
        </div>

        <div>
          <button
            type="button"
            className="btn btn-primary main_button"
            onClick={() => navigate("/services")}
          >
            Know More
          </button>
        </div>
      </div>

      <div className="home_cards">
        <h1 className="home_cards_head">Our Products</h1>

        <div className="home_card">
          {HomeData.Home
            ? HomeData.Home[2].sub.map((data, index) => {
                return (
                  <div className="card card-cascade">
                    <div className="view view-cascade overlay">
                      <img
                        className="card-img-top imgp"
                        src={data.image}
                        alt="Card image cap"
                      />
                    </div>
                    <div className="card-body card-body-cascade text-center">
                      <h4 className="card-title">{data.heading}</h4>
                    </div>
                    <a className="card_btn" href={data.path}>
                      <div className="card-footer text-muted text-center">
                        Read More
                      </div>
                    </a>
                  </div>
                );
              })
            : null}
        </div>

        <div>
          <button
            type="button"
            className="btn btn-primary main_button"
            onClick={() => navigate("/products")}
          >
            Know More
          </button>
        </div>
      </div>

      <div
        className="fixed-bg bg-3"
        style={{
          backgroundImage: `url(${
            HomeData.HomeBackgroundImage
              ? HomeData.HomeBackgroundImage[0].image
              : null
          })`,
        }}
      >
        <div className="customers">
          {HomeData.Home
            ? HomeData.Home[3].sub.map((data, index) => {
                return (
                  <div className="numbers">
                    <h1>
                      <CountUp
                        start={focus ? 0 : null}
                        end={data.number}
                        redraw={true}
                        suffix=" +"
                        duration={5}
                      >
                        {({ countUpRef }) => (
                          <VisibilitySensor onChange={visible} delayedCall>
                            <span ref={countUpRef} />
                          </VisibilitySensor>
                        )}
                      </CountUp>
                    </h1>
                    <h3>{data.heading}</h3>
                  </div>
                );
              })
            : null}
        </div>
      </div>

      <div className="compliance_body">
        <div>
          <h1 className="compliance_head">Quality Compliance</h1>
          <div className="compliance_description">
            <p>{HomeData.Home ? HomeData.Home[4].description : null}</p>
          </div>
        </div>
      </div>
    </div>
    
  );
}

export default HomeCard;
