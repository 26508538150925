import React from "react";
import "./Mission.css";
import { AboutData } from "../App";

function Mission() {
  return (

    <div className="mission_vision_body">
      <div className="mission">
        <div className="mission_img">
          <img src={AboutData.About ? AboutData.About[2].image : null} />
        </div>

        <div>
          <h1 className="mission_head">Our Mission</h1>

          <div className="mission_description">
            {AboutData.About
              ? AboutData.About[2].description.map((data, index) => {
                  return <p>{data.content}</p>;
                })
              : null}
          </div>
        </div>
      </div>

      <div className="vision">
        <div>
          <h1 className="vision_head">Our Vision</h1>

          <div className="vision_description">
            {AboutData.About
              ? AboutData.About[3].description.map((data, index) => {
                  return <p>{data.content}</p>;
                })
              : null}
          </div>
        </div>

        <div className="vision_img">
          <img src={AboutData.About ? AboutData.About[3].image : null} />
        </div>
      </div>
    </div>
    
  );
}

export default Mission;
