import React from "react";
import "./TestingContent.css";
import { ProductsData } from "../App";

function MedicalContent(props) {
  return (

    <div className="testing">
      <div
        className="main"
        style={{
          backgroundImage: `url(${
            ProductsData.ProductsBackgroundImage
              ? ProductsData.ProductsBackgroundImage[0].image
              : null
          })`,
        }}
      >
        <div className="overlay">
          <h1 className="heading">Medical Products</h1>
        </div>
      </div>

      <div className="medical_body">
        {props.image ? (
          <div className="image">
            <img src={props.image} />
          </div>
        ) : null}

        {props.sliding ? (
          <div className="image">
            <div
              id="carouselDarkVariant"
              className="carousel slide carousel-fade z-depth-1-half carousel-dark"
              data-ride="carousel"
            >
              {/*Indicators*/}
              <ol className="carousel-indicators">
                <li
                  data-target="#carouselDarkVariant"
                  data-slide-to={0}
                  className="active"
                />
                {props.sliding.slice(1, 10).map((data) => {
                  return (
                    <li
                      data-target="#carouselDarkVariant"
                      data-slide-to={data.id}
                    />
                  );
                })}
              </ol>
              {/*/.Indicators*/}
              {/*Slides*/}
              <div className="carousel-inner" role="listbox">
                <div className="carousel-item active">
                  <div className="view">
                    <img
                      className="d-block w-100"
                      src={props.sliding[0].image}
                      alt="First slide"
                    />
                  </div>
                </div>

                {props.sliding.slice(1, 10).map((data) => {
                  return (
                    <div className="carousel-item">
                      <div className="view">
                        <img
                          className="d-block w-100"
                          src={data.image}
                          alt="Second slide"
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
              {/*/.Slides*/}
              {/*Controls*/}
              <a
                className="carousel-control-prev"
                href="#carouselDarkVariant"
                role="button"
                data-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                />
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="carousel-control-next"
                href="#carouselDarkVariant"
                role="button"
                data-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                />
                <span className="sr-only">Next</span>
              </a>
              {/*/.Controls*/}
            </div>
          </div>
        ) : null}

        <div className="title">
          <h1>{props.title}</h1>
        </div>

        {props.descriptions
          ? props.descriptions.map((data) => {
              return (
                <div>
                  <div className="description">
                    <p>{data.description}</p>
                  </div>

                  {data.subs ? (
                    <div className="sub_description">
                      <ul>
                        {data.subs.map((data1) => {
                          return <li>{data1.content}</li>;
                        })}
                      </ul>
                    </div>
                  ) : null}
                </div>
              );
            })
          : null}

        <div className="description">
        {props.description
          ? props.description.map((data) => {
              return (
                  <p>{data.content}</p>
              );
            })
          : null}
          </div>

        {props.sub
          ? props.sub.map((data) => {
              return (
                <div className="sub">
                  <div className="sub_title">
                    <h3>{data.title}</h3>
                  </div>

                  {data.description ? (
                    <div className="sub_description">
                      <ul>
                        {data.description.map((data1) => {
                          return <li>{data1.content}</li>;
                        })}
                      </ul>
                    </div>
                  ) : null}

                  {data.image ? (
                    <div className="sub_image">
                      {data.image.map((data1) => {
                        return <img src={data1.image} className="image" />;
                      })}
                    </div>
                  ) : null}
                </div>
              );
            })
          : null}

        {props.table1 ? (
          <div className="table tablerounededCorner">
            <table className="roundedTable ">
              {props.table1.map((data) => {
                return (
                  <tr>
                    <th>{data.title}</th>
                    <td>{data.value}</td>
                  </tr>
                );
              })}
            </table>
          </div>
        ) : null}

        {props.table2 ? (
          <div className="sub">
            <div className="sub_title">
              <h3>UVC Box Sterilizer Specifications</h3>
            </div>

            <div className="table tablerounededCorner table1">
              <table className="roundedTable">
                <tr>
                  <th>{props.table2[0].title1}</th>
                  <th>{props.table2[0].title2}</th>
                  <th>{props.table2[0].title3}</th>
                  <th>{props.table2[0].title4}</th>
                </tr>
                {props.table2.slice(1, 10).map((data) => {
                  return (
                    <tr>
                      <th>{data.title}</th>
                      <td>{data.value}</td>
                      <td>{data.value1}</td>
                      <td>{data.value2}</td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </div>
        ) : null}

      </div>
    </div>

  );
}

export default MedicalContent;
