import React from "react";
import "./Certificate.css";
import { CertificationData } from "../App";
import LightGallery from "lightgallery/react";

// import styles
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import "lightgallery/css/lg-fullscreen.css";

import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";
import lgFullScreen from "lightgallery/plugins/fullscreen";

function Certificate() {
  return (

    <div className="certificate">
      <div
        className="main"
        style={{
          backgroundImage: `url(${
            CertificationData.CertificationBackgroundImage
              ? CertificationData.CertificationBackgroundImage[0].image
              : null
          })`,
        }}
      >
        <div className="overlay">
          <h1 className="headings">Certification</h1>
        </div>
      </div>

      <h1 className="certificate_head">Certification</h1>

      <div className="certificate_body">
        {CertificationData.Certification ? (
          <LightGallery
            elementClassNames="certificates"
            plugins={[lgZoom, lgThumbnail, lgFullScreen]}
            mode="lg-fade"
          >
            {CertificationData.Certification.map((data, index) => {
              return (
                <a
                  data-lg-size={data.size}
                  className="gallery-item"
                  data-src={data.image}
                  data-sub-html={data.text}
                >
                  <img src={data.image} />
                  <p>{data.heading}</p>
                </a>
              );
            })}
          </LightGallery>
        ) : null}
      </div>
    </div>
    
  );
}

export default Certificate;
