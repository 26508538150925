import React from "react";
import "./Footer.css";
import { NavLink } from "react-router-dom";
import {ContactData} from './App';

function Footer() {
    
    return ( 
        <div className="footer">

            <footer>
                <div className="top_header">
                    <section>
                        <span><i className="fa fa-map-marker"></i></span>
                        <span>{ContactData.Contact ? ContactData.Contact[0].description : null}</span>
                    </section>

                    <section>
                        <span><i className="fa fa-phone"></i></span>
                        <span>{ContactData.Contact ? ContactData.Contact[1].description : null}</span>
                    </section>

                    <section>
                        <span><i className="fa fa-envelope"></i></span>
                        <span>{ContactData.Contact ? ContactData.Contact[2].description : null}</span>
                    </section>
                </div>

                <span className="border-shape"></span>

                <div className="bottom_content">
                    <section>
                        <NavLink to="/">Home</NavLink>
                        <NavLink to="/about">About us</NavLink>
                        <NavLink to="/services">Services</NavLink>
                        <NavLink to="/products">Products</NavLink>
                        <NavLink to="/certification">Certification</NavLink>
                        <NavLink to="/contact">Contact</NavLink>
                    </section>
                </div>

                <div className="copyright">
                    Copyright © {new Date().getFullYear()} HERRMANN RESEARCH PRIVATE LIMITED - All rights reserved
                </div>
            </footer>
        </div>
    );
}

export default Footer;