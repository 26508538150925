import React, {useEffect, useState} from 'react';
import './Contact.css';
import axios from 'axios';
import {ContactData} from '../App';
import { useForm } from "react-hook-form";




function Contact() {

    const [test, setTest]= useState('');

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
      } = useForm();
    
    const handletest=(e)=>{
        const gettestid = e.target.value;
        setTest(gettestid);
        console.log(gettestid);
    }

      const onSubmit1 = async(data) => {

        const url = ContactData.ContactSendMail[0].path;

        const datas = {
            fname: data.fname,
            lname: data.lname,
            company_name: data.company_name,
            company_address: data.company_address,
            email: data.email,
            mobile: data.mobile,
            type: test,
            test_name: data.test_name ? data.test_name :"",
            quantity: data.quantity ? data.quantity :"",
            description: data.description ? data.description :"",
            size: data.size ? data.size :"",
            message: data.message
        }
        
        const headers = {
            'Accept': 'application/json',
            'content-type': 'application/json'
        }

        await axios.post(url, datas, {headers}) 
            .then(response=> {
                console.log("response", response)
                if(response.status == 200) {
                    alert("Inquiry has been sent")
                    console.log(data)
                }
            }).catch(e => {
                console.log("e", e)
                alert("Inquiry has not been sent")
            })
    };


      


  return (
      
     <div className='contact'>
        <div className='main' style={{ backgroundImage: `url(${ContactData.ContactBackgroundImage ? ContactData.ContactBackgroundImage[0].image : null})`}}>
            <div className='overlay'>
                <h1 className='headings'>Contact</h1>
            </div>
        </div>

        <h1 className='contact_head'>Contact</h1>

        <div className='contact_body'>
            
            <div className="contact_form">
                <div className="card">
                    <div className="card-body">
                        <div className="card-title text-center">
                            <h4 className="">Enquiry Form</h4>
                        </div>
            
                        <form className='form' onSubmit={handleSubmit(onSubmit1)}>

                            <div className="rows">
                                {/* Name input */}
                                {/* First Name input */}
                                <div className="form-outline mb-4">
                                    <label className="form-label" htmlFor="fname"><span>*  </span>First Name</label>
                                    <input type="text" className="form-control" 
                                    {...register("fname", {
                                        required: true,
                                        maxLength: 20,
                                        pattern: /^[A-Za-z]+$/i
                                    })}/>
                                    {errors.fname && errors.fname.type === "required" && <span>This is required</span>}
                                    {errors.fname && errors.fname.type === "maxLength" && <span>Max length exceeded</span>}
                                    {errors.fname && errors.fname.type === "pattern" && <span>Alphabetical characters only</span>}
                                </div>
                            
                                {/* Email input */}
                                {/* Last Name input */}
                                <div className="form-outline mb-4">
                                    <label className="form-label" htmlFor="lname"><span>*  </span>Last Name</label>
                                    <input type="text" className="form-control" 
                                    {...register("lname", {
                                        required: true,
                                        maxLength: 20,
                                        pattern: /^[A-Za-z0-9]+$/i
                                    })}/>
                                    {errors.lname && errors.lname.type === "required" && <span>This is required</span>}
                                    {errors.lname && errors.lname.type === "maxLength" && <span>Max length exceeded</span>}
                                    {errors.lname && errors.lname.type === "pattern" && <span>Alphabetical characters only</span>}
                                </div>
                            </div>

                            <div className="form-outline mb-4">
                                <label className="form-label" htmlFor="form4Example3"><span>*  </span>Company Name</label>
                                <input type="text" className="form-control"  {...register("company_name", {
                                    required: true,
                                    pattern: /^[^\s]+(\s+[^\s]+)*$/i
                                })}/>
                                {errors.company_name && errors.company_name.type === "required" && <span>This is required</span>}
                                {errors.company_name && errors.company_name.type === "pattern" && <span>Invalid input</span>}
                            </div>

                            <div className="form-outline mb-4">
                                <label className="form-label" htmlFor="form4Example3"><span>*  </span>Company Address</label>
                                <textarea className="form-control" rows={2} defaultValue={""} {...register("company_address", {
                                    required: true,
                                    pattern: /^[^\s]+(\s+[^\s]+)*$/i
                                })}/>
                                {errors.company_address && errors.company_address.type === "required" && <span>This is required</span>}
                                {errors.company_address && errors.company_address.type === "pattern" && <span>Invalid input</span>}
                            </div>

                            <div className="rows">
                            
                                {/* Name input */}
                                {/* Email input */}
                                <div className="form-outline mb-4">
                                    <label className="form-label" htmlFor="form4Example2"><span>*  </span>Email address</label>
                                    <input type="email"  className="form-control" {...register("email", {
                                        required: true,
                                        pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
                                    })}/>
                                    {errors.email && errors.email.type === "required" && <span>This is required</span>}
                                    {errors.email && errors.email.type === "pattern" && <span>Invalid EmailID</span>}
                                </div>
                        
                                <div className="form-outline mb-4">
                                    <label className="form-label" htmlFor="form4Example2"><span>*  </span>Mobile Number</label>
                                    <input type="tel" className="form-control" pattern="[0-9]{10}" {...register("mobile", {
                                        required: true,
                                        minLength: 10,
                                        maxLength: 10
                                    })}/>
                                    {errors.mobile && errors.mobile.type === "required" && <span>This is required</span>}
                                    {errors.mobile && errors.mobile.type === "minLength" && <span>Invalid Number</span>}
                                    {errors.mobile && errors.mobile.type === "maxLength" && <span>Invalid Number</span>}
                                </div>
                            </div>

                            <div className="form-outline mb-4">
                                <label className="form-label" htmlFor="form4Example3"><span>*  </span>Quote Type</label>
                                <select className="form-select" aria-label="Default select example" {...register("type", {
                                    required: true
                                    })} onChange={handletest}>

                                    <option disabled="true" selected value="">Select quote type</option>
                                    <option key="TestingData" value="Inquiry for Environmental Testing">Inquiry for Environmental Tests</option>
                                    <option key="MedicalData" value="Purchase of UV Air Sterilizer">Purchase of UV Air Sterilizer</option>
                                    <option key="Other" value="Other">Other</option>
                                </select>
                                {errors.type && errors.type.type === "required" && <span>This is required</span>}
                            </div>


                            {test === 'Inquiry for Environmental Testing'? 
                    
                                <div>
                                    <div className="rows">

                                        <div className="form-outline mb-4">
                                            <label className="form-label" htmlFor="fname"><span>*  </span>Test Name</label>
                                            <input name="test_name" type="text" className="form-control" {...register("test_name", {
                                                required: true,
                                                pattern: /^[^\s]+(\s+[^\s]+)*$/i
                                            })}/>
                                            {errors.test_name && errors.test_name.type === "required" && <span>This is required</span>}
                                            {errors.test_name && errors.test_name.type === "pattern" && <span>Invalid input</span>}
                                        </div>
                                    
                                        <div className="form-outline mb-4">
                                            <label className="form-label" htmlFor="lname"><span>*  </span>Quantity</label>
                                            <input name="quantity" type="number" className="form-control" {...register("quantity", {
                                                required: true
                                            })}/>
                                            {errors.quantity && errors.quantity.type === "required" && <span>This is required</span>}
                                        </div>   
                                    </div>

                                    <div className="form-outline mb-4">
                                        <label className="form-label" htmlFor="form4Example3"><span>*  </span>Test Item Description</label>
                                        <textarea name="description" className="form-control" rows={2} defaultValue={""}  {...register("description", {
                                            required: true,
                                            pattern: /^[^\s]+(\s+[^\s]+)*$/i
                                        })}/>
                                        {errors.description && errors.description.type === "required" && <span>This is required</span>}
                                        {errors.description && errors.description.type === "pattern" && <span>Invalid input</span>}
                                    </div>
                                </div>
                    
                                :<div>
                                {test === 'Purchase of UV Air Sterilizer'? 
                                    <div>
                                    <div className="form-outline mb-4">
                                            <label className="form-label" htmlFor="fname"><span>*  </span>Type of Filter</label>
                                            <input name="test_name" type="text" className="form-control" {...register("test_name", {
                                                required: true,
                                                pattern: /^[^\s]+(\s+[^\s]+)*$/i
                                            })}/>
                                            {errors.test_name && errors.test_name.type === "required" && <span>This is required</span>}
                                            {errors.test_name && errors.test_name.type === "pattern" && <span>Invalid input</span>}
                                        </div>

                                        <div className="rows">  
                                            <div className="form-outline mb-4">
                                                <label className="form-label" htmlFor="fname"><span>*  </span>Quantity</label>
                                                <input name="quantity" type="number" className="form-control" {...register("quantity", {
                                                required: true,
                                                })}/>
                                                {errors.quantity && errors.quantity.type === "required" && <span>This is required</span>}
                                            </div>
                                                
                                            <div className="form-outline mb-4">
                                                <label className="form-label" htmlFor="lname"><span>*  </span>Size of Room</label>
                                                <input name="size" type="text" className="form-control" {...register("size", {
                                                    required: true,
                                                    pattern: /^[^\s]+(\s+[^\s]+)*$/i
                                                })}/>
                                                {errors.size && errors.size.type === "required" && <span>This is required</span>}
                                                {errors.size && errors.size.type === "pattern" && <span>Invalid input</span>}
                                            </div>   
                                        </div>
                                    </div> :

                                    <div className="form-outline mb-4">
                                        <label className="form-label" htmlFor="form4Example3"><span>*  </span>Description</label>
                                        <textarea name="description" className="form-control" rows={2} defaultValue={""} {...register("description", {
                                            required: true,
                                            pattern: /^[^\s]+(\s+[^\s]+)*$/i
                                        })}/>
                                    {errors.description && errors.description.type === "required" && <span>This is required</span>}
                                    {errors.description && errors.description.type === "pattern" && <span>Invalid input</span>}
                                </div>
                                }
                            </div>
                            }


                            {/* Message input */}
                            <div className="form-outline mb-4">
                                <label className="form-label" htmlFor="form4Example3"><span>*  </span>How did you hear about us</label>
                                <textarea name="message" className="form-control" rows={2} defaultValue={""} {...register("message", {
                                    required: true,
                                    pattern: /^[^\s]+(\s+[^\s]+)*$/i
                                })}/>
                                {errors.message && errors.message.type === "required" && <span>This is required</span>}
                                {errors.message && errors.message.type === "pattern" && <span>Invalid input</span>}
                            </div>

                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" {...register("check", {
                                    required: true,
                                })}/>
                                <label className="form-check-label" for="flexCheckDefault">Please verify you are not a bot</label>
                                {errors.check && errors.check.type === "required" && <div>{alert("Please check the box")}</div>}
                            </div>

                            {/* Submit button */}
                            <div className='button'>
                                <button type="submit" className="btn btn-primary btn-block mb-4">Send</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        
            
            <div className='contact_details'>
                <h2>Contact Details</h2>
                {ContactData.Contact?
                ContactData.Contact.slice(0, 3).map((data, index) => {
                    return (
                        <div className='details'>
                            <p className='details_title'>{data.title}</p>
                            <p className='details_description'>{data.description}</p>
                        </div>
                    );
                }) : null
                }

                <div className='details'>
                    <p className='details_title'>{ContactData.Contact ? ContactData.Contact[3].title : null}</p>
                    <p className='details_description'><a href={ContactData.Contact ? ContactData.Contact[3].description : null}>{ContactData.Contact ? ContactData.Contact[3].description : null}</a></p>
                </div>
                <div className='details card'>
                    <a className='' href={ContactData.Contact ? ContactData.Contact[4].path : null} target="_blank">
                        <img src={ContactData.Contact ? ContactData.Contact[4].image : null}/>
                    </a>    
                </div>
            </div>
        
        </div>
    </div>
  )
}

export default Contact;