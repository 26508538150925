import React, { useState, useEffect } from "react";
import axios from "axios";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import "./App.css";

import Navbar from "./Navbar/Navbar";
import Footer from "./Footer";
import SlidingImages from "./Home/SlidingImages";
import HomeCard from "./Home/HomeCard";

import About from "./About/About";

import TestingSide from "./Services/TestingSide";
import MedicalSide from "./Services/MedicalSide";
import TestingContent from "./Services/TestingContent";
import MedicalContent from "./Services/MedicalContent";
import TestingMain from "./Services/TestingMain";
import MedicalMain from "./Services/MedicalMain";

import Certificate from "./Certification/Certificate";

import Contact from "./Contact/Contact";

let HomeData = null;
let AboutData = null;
let ServicesData = null;
let ProductsData = null;
let CertificationData = null;
let ContactData = null;

function App() {
  const [HomeDatas, setHomeDatas] = useState([]);
  const [AboutDatas, setAboutDatas] = useState([]);
  const [ServicesDatas, setServicesDatas] = useState([]);
  const [ProductsDatas, setProductsDatas] = useState([]);
  const [CertificationDatas, setCertificationDatas] = useState([]);
  const [ContactDatas, setContactDatas] = useState([]);

  useEffect(() => {
    axios
      .get("/data/Home.json")
      .then((res) => setHomeDatas(res.data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .get("/data/About.json")
      .then((res) => setAboutDatas(res.data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .get("/data/Services.json")
      .then((res) => setServicesDatas(res.data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .get("/data/Products.json")
      .then((res) => setProductsDatas(res.data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .get("/data/Certification.json")
      .then((res) => setCertificationDatas(res.data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .get("/data/Contact.json")
      .then((res) => setContactDatas(res.data))
      .catch((err) => console.log(err));
  }, []);

  HomeData = HomeDatas;
  AboutData = AboutDatas;
  ServicesData = ServicesDatas;
  ProductsData = ProductsDatas;
  CertificationData = CertificationDatas;
  ContactData = ContactDatas;

  return (
    <>
      <Router>

        {/* Navbar */}
        <Navbar />

        
        <div>
          <Routes>

            {/* Home */}
            <Route
              path="/"
              element={
                <div>
                  <SlidingImages />
                  <HomeCard />
                </div>
              }
            ></Route>


            {/* About Us */}
            <Route
              path="/about"
              element={
                <div>
                  <About />
                </div>
              }
            ></Route>


            {/* Services */}
            <Route
              path="/services"
              element={
                <div>
                  <TestingSide />
                  <TestingMain />
                </div>
              }
            ></Route>

            {ServicesData.TestingData
              ? ServicesData.TestingData.map((data, index) => {
                  console.log(data);
                  return (
                    <>
                      <Route
                        exact
                        path={data.path}
                        key={index}
                        element={
                          <div>
                            <TestingSide />

                            <TestingContent
                              title={data.title}
                              image={data.image}
                              sliding={data.sliding}
                              description={data.description}
                              parameters={data.parameters}
                              sub_title={data.subtitle}
                              standards={data.standards}
                            />
                          </div>
                        }
                      ></Route>
                    </>
                  );
                })
            : null}



            {/* Products */}
            <Route
              path="/products"
              element={
                <div>
                  <MedicalSide />
                  <MedicalMain />
                </div>
              }
            ></Route>

            {ProductsData.MedicalData
              ? ProductsData.MedicalData.map((data, index) => {
                  return (
                    <Route
                      exact
                      path={data.path}
                      key={index}
                      element={
                        <div>
                          <MedicalSide />

                          <MedicalContent
                            title={data.title}
                            image={data.image}
                            sliding={data.sliding}
                            descriptions={data.descriptions}
                            description={data.description}
                            sub={data.sub}
                            table1={data.table1}
                            table2={data.table2}
                          />
                        </div>
                      }
                    ></Route>
                  );
                })
            : null}


            {/* Certification */}
            <Route
              path="/certification"
              element={
                <div>
                  <Certificate />
                </div>
              }
            ></Route>


            {/* Contact Us */}
            <Route
              path="/contact"
              element={
                <div>
                  <Contact />
                </div>
              }
            ></Route>

          </Routes>

        </div>

        <Footer/>
      </Router>
    </>
  );
}

export default App;
export {
  HomeData,
  AboutData,
  ServicesData,
  ProductsData,
  CertificationData,
  ContactData,
};
